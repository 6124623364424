.showcase-component {
    width: 1200px;
    margin: auto;
    padding: 32px 0px;
}

.alternative-style {
    width: 100%;
    margin: auto;
    padding: 0px 0px;
    margin: 32px 0px;
    background-color: var(--cinza-principal);
}

#novidade {
    color: var(--cor-principal);
    font-size: 16px;
}

.alternative-style .showcase-inside-alternative {
    width: 100%;
    margin: auto;
    padding-right: 200px;
}

.alternative-left .showcase-inside-alternative {
    padding-right: 0px;
    padding-left: 200px;
}

.alternative-style .showcase-inside-alternative img {
    width: 690px;
    max-height: 690px;
    object-fit: cover;
}

.showcase-inside {
    display: flex;
    align-items: center;
    gap: 12px;
    text-align: center;
    flex-wrap: nowrap;
}

.showcase-inside-alternative {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.showcase-right-side p {
    font-size: 42px;
    font-family: sans-serif;
    text-transform: uppercase;
}

.showcase-right-side {
    width: 360px;
    text-align: center;
}

.btn-showcase {
    background-color: black;
    color: white;
    padding: 12px 24px;
    margin: 12px 0px;
}

.showcase-inside-full {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;
    margin: auto;
    justify-content: center;
}

.showcase-box {
    width: 615px;
    height: 500px;
}

.showcase-box-full {
    width: 100%;
    height: auto;

}


.showcase-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.showcase-box-full img {
    width: 1200px;
    height: 500px;
    object-fit: cover;
}

@media screen and (max-width: 900px) {
    .showcase-inside-full {
        display: block;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .showcase-box-full {
        width: 100%;
        margin-bottom: 20px;
    }

    .showcase-box-full img {
        width: 100%;
        height: 500px;
        object-fit: cover;
    }

    .showcase-box {
        width: 95%;
        margin: auto;
        margin-bottom: 80px;
    }

    .showcase-inside {
        display: flex;
        align-items: center;
        gap: 24px;
        text-align: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .showcase-component {
        width: 100%;
        padding: 32px 0px;
    }

    .descriptionshowcase {
        padding: 10px;
    }

    .showcase-inside-alternative {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .showcase-inside-alternative img {
        width: 330px;
        margin: 24px 0px;
    }

    .showcase-right-side p {
        font-size: 28px;
        font-family: sans-serif;
        text-transform: uppercase;
    }

    .showcase-right-side {
        width: 360px;
        text-align: center;
    }

    .alternative-style .showcase-inside-alternative img {
        width: 100%;
        max-height: 490px;
        object-fit: cover;
    }
    
    .showcase-left-side {
        padding-top: 0;
        margin-top: 0;
        width: 100%;
    }

    .alternative-style {
        padding-top: 0;
    }


    .alternative-style .showcase-inside-alternative {
        width: 100%;
        margin: auto;
        padding-right: 0px;
    }

    .alternative-left .showcase-inside-alternative {
        padding-right: 0px;
        padding-left: 0px;
        flex-direction: column-reverse;
    }
}