.dashboard-laris-acessorios {
    padding: 20px;
    background-color: #f9f9f9;
}

.dashboard-content {
    max-width: 1200px;
    margin: 0 auto;
}

.Facility-Content-Page .header {
    text-align: center;
    margin-bottom: 30px;
}

.facility-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.facility-form h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.facility-form input,
.facility-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.facility-form button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.facility-form button:hover {
    background-color: #45a049;
}

.facility-list {
    margin-top: 30px;
}

.facility-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.facility-item img {
    max-width: 100px;
    height: auto;
    margin-right: 20px;
}

.facility-details {
    flex-grow: 1;
}

.facility-details h3 {
    font-size: 1.2rem;
    font-weight: bold;
}

.facility-details p {
    color: #555;
}

.categoryImageFacilitys {
    width: 400px;
}
#colorHigh {
    color: var(--cor-principal)
}

.item-flex {
    display: flex;
    align-items: center;
}