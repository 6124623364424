.adminPage {
    height: 100vh;
    margin-top: -100px;
}

.backgroundAdminPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5vh;
    background-color: var(--cor-principal);
    z-index: 1;
}

.react-grid-item {
    background-color: white;
}

.previewimage {
    width: 50px;
    height: 50px;
}

.graphic-Component {
    width: 100%;
}

.dashboard-content {
    margin-top: -90px;
    border-top-right-radius: 24px;
    padding: 24px;
}

.dashboard-laris-acessorios {
    background-color: var(--cinza-principal);
    width: 82%;
    height: 100vh;
    margin-left: 250px;
    border-radius: 24px;
    padding: 30px;
    /* Adicionando padding */
}

.dashboard-header-component__inside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
}

.dashboard-header-component-action-btns {
    display: flex;
    gap: 8px;
    align-items: center;
}

.dashboard-header-component-action-btns button {
    width: 40px !important;
    height: 40px !important;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: gray;
    background-color: white;
    border-radius: 100%;
    display: flex;
}

.dashboard-header-component {
    margin-left: 250px;
    position: fixed;
    top: 0;
    padding: 24px 0px;
}

/* Estilo do Dashboard */
.dashboard-laris-acessorios h1 {
    text-align: left;
    font-size: 26px;
    margin-bottom: 30px;
}

/* Layout dos Widgets */
.dashboard-widgets {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
}

.widget {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    color: black;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    border-left: 5px solid transparent;
}

.widget:hover {
    transform: translateY(-5px);
}

.widget h2 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #be0a45;
}

.widget-value {
    font-size: 36px;
    font-weight: bold;
    color: #be0a45;
}

.widget-detail {
    font-size: 14px;
    color: #999;
}

.userContentTable {
    display: flex;
    align-items: center;
    gap: 4;
}

.userContentTable img {
    width: 20px;
    height: 20px;
}

.widget.small {
    grid-column: span 1;
}

.widget.medium {
    grid-column: span 2;
}

.widget.full {
    grid-column: span 3;
}

/* Tabelas de Produtos e Pedidos */
.dashboard-laris-acessorios table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.dashboard-laris-acessorios th,
.dashboard-laris-acessorios td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.dashboard-laris-acessorios th {
    background-color: #f5f5f5;
    color: #555;
}

.dashboard-laris-acessorios td {
    background-color: #fafafa;
}

.dashboard-laris-acessorios tr:hover {
    background-color: #f1f1f1;
}

/* Cores dos Widgets */
.widget.users,
.widget.products,
.widget.sales,
.widget.orders {
    background-color: var(--cinza-principal);
}

.widget.cash {
    background-color: #be0a45;
    color: white;
}

.widget.cash h1,
.widget.widget.cash p,
.widget.cash h2 {
    color: white !important;
}

.widget.stock {
    background-color: var(--cinza-principal);
    border-left: 5px solid #be0a45;
}

/* Efeitos de Hover */
.widget:hover {
    /* Cor suave de fundo ao passar o mouse */
}

/* Responsividade */
@media (max-width: 768px) {
    .dashboard-laris-acessorios {
        width: 100%;
        /* Ajustar a largura para 100% */
        margin-left: 0;
        /* Remover margem à esquerda */
        padding: 20px;
        /* Ajustar o padding */
    }

    .dashboard-widgets {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media (max-width: 480px) {
    .dashboard-laris-acessorios {
        padding: 15px;
        /* Ajustar o padding */
    }

    .dashboard-laris-acessorios h1 {
        font-size: 20px;
        /* Ajustar o tamanho da fonte */
    }

    .widget h2 {
        font-size: 18px;
        /* Ajustar o tamanho da fonte */
    }

    .widget-value {
        font-size: 24px;
        /* Ajustar o tamanho da fonte */
    }

    .dashboard-header-component {
        padding: 10px;
        /* Ajustar o padding */
    }
}

@media screen and (max-width: 900px) {
    .dashboard-laris-acessorios {
        background-color: var(--cinza-principal);
        width: 100%;
        height: auto;
        /* Ajustar a altura para auto */
        margin-left: 0;
        /* Remover margem à esquerda */
        border-radius: 24px;
        padding-top: 20px;
        margin-top: 160px;
        /* Ajustar o padding */
    }

    .hide-mobile {
        display: none
    }

    .dashboard-widgets {
        grid-template-columns: 1fr;
        /* Todos os widgets ocupam uma linha inteira */
        grid-template-areas:
            "users"
            "products"
            "sales"
            "orders"
            "stock"
            "mobile";
    }

    .widget.small,
    .widget.medium,
    .widget.full {
        grid-column: span 1;
        /* Todos os widgets ocupam uma linha inteira no mobile */
    }

    .dashboard-header-component__inside {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .dashboard-header-component {
        margin-left: 0;
        /* Remover margem à esquerda */
        position: fixed;
        top: 0;
        padding: 10px 0;
        /* Ajustar o padding */
    }
}